<template>

    <div class="app ">
        <div class="Chatbot_w2 " >

          <div class="componente1 vert-move"></div>
          
           <div class="sub_componente1 "></div>

           <div class="top container-fluid">
            <div class="row">
              <div class="col-md-6 mt-3">
                <div class="derecha mr-3">
                  <h1 class="texto_1"> <b> Whatsapp </b> </h1>
                  <h1 class="texto_1"><b> chatbot </b></h1>
                  <div class="tamaño_div">
                    <p class="texto2">un asistente digital con inteligencia artificial que te ayudará a hacer las ventas y atención a clientes a tu empresa </p>
                  </div>        
                    
                  <button class="btn color-b" @click="goToStep(0)"><b> MÁS INFORMACIÓN </b></button>
                </div>
                
                
              </div>
                <div class="col-md-6">
                  <div class="div_position"><img class="mr-5 telefono" src="../assets/telefono1.png" alt=""> <img class="icon_W2" src="../assets/componente_26.png" alt="">

                  
                  </div> 
                </div>
            </div>
          </div>

        </div>  

        <div class="Chatbot_w_2">
          <Chatbot_w_2 />
        </div>
        <div class="Chatbot_w_3 ">
          <Chatbot_w_3 />
        </div>
        <div class="Chatbot_w_4 ">
          <Chatbot_w_4 />
        </div>


    </div>
    

</template>

<script>
import Chatbot_w_2 from '../components/Chatbot_w_2.vue'
import Chatbot_w_3 from '../components/Chatbot_w_3.vue'
import Chatbot_w_4 from '../components/Chatbot_w_4.vue'

export default {
 name: 'Chatbot_w2',
  components: {
    Chatbot_w_2,
    Chatbot_w_3,
    Chatbot_w_4,
    
  },
  data(){
      return{
          step: 1

      }
  },
  async mounted(){

  },
  methods:{
      goToStep(item){
          this.step=item

      },
      
  }
}
</script>
<style scoped>

.Chatbot_w2 {
  display:flex ;
  height: 789px;
  position: relative;
  background-color: rgb(22, 219, 123);
  
}
.componente1{
  background-image: url("../assets/componente_1.png");
  width:295px;
  height:250px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;

  
} 
.sub_componente1{
  background-image: url("../assets/componente_28.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width:305px;
  height:300px;
  right:0px ;
  top: 500px;
  
}
.div_position{
  text-align:left;
}
.telefono{
  width: 35%;
  height: 35%;
  
}
.top{
  position: absolute;
  top: 23%;
  }
.icon_W2{
  max-width: 30%;
  max-height: 30%;
  margin-bottom: 30%;
  
}
.chat_w{
  text-align: left;
  
}
.texto_1{
  font-size: 80px;
  color: rgb(255, 255, 255);
  text-align: left;
}
.texto2{
  font-size: 24px;
  text-align: left;
  color: rgb(255, 255, 255);
}
.apps2{
    max-height:200px;
    max-width: 200px;
}
.color-b{
      background-color: rgb(255, 255, 255);
      color: rgb(37, 113, 255);
      border-radius: 18px;
      width: 200px;
      height: 43px;
      font-size: 15px;
      margin-right:50%;
      }

.derecha{
  margin-left: 30%;
  
}
.tamaño_div{
  width: 100%;
}
.vert-move:hover {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}

@-webkit-keyframes mover {
    0% { transform: translateX(0); }
    100% { transform: translateX(-30px); }
}

@media only screen and (max-width: 780px) {
  .texto_1{
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
    }
    .derecha{
    margin-left: 0%;
  }
  .tamaño_div{
    width: 100%;
}
.texto2{
  font-size: 22px;
  text-align: center;
  color: rgb(255, 255, 255);

}
.color-b{
      background-color: rgb(255, 255, 255);
      color: rgb(37, 113, 255);
      border-radius: 18px;
      width: 200px;
      height: 43px;
      font-size: 16px;
      margin-right:0%;
      
      }
  
  .telefono, .icon_W2{
    display:none;
  }
  .Chatbot_w2 {
  display:flex ;
  height: 750px;
  position: relative;
  background-color: rgb(22, 219, 123);
  
}
.componente1{
      top: 8%; 
      max-height:50%;
      max-width: 50%;
    }
    .sub_componente1{
    top: 65%;
    max-width:50%;
    max-height:50%;
    
  }
}
@media only screen and (max-width: 800px) and (min-width: 780px) {
  .texto_1{
    font-size: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
    }
    .derecha{
    margin-left: 3%;
  }
  .tamaño_div{
    width: 100%;
}
.texto2{
  font-size: 22px;
  text-align: center;
  color: rgb(255, 255, 255);

}
.componente1{
  background-image: url("../assets/componente_1.png");
  width:595px;
  height:210px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;
} 
.color-b{
      background-color: rgb(255, 255, 255);
      color: rgb(37, 113, 255);
      border-radius: 18px;
      width: 200px;
      height: 43px;
      font-size: 15px;
      margin-left:20%;
      }
      .Chatbot_w2 {
        height: 500px;
}
.sub_componente1{
  top: 310px;
   width:205px;
  height:200px;
  
}
.telefono{
  width: 45%;
  height: 45%;
  
}

  


}
</style>