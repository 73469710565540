import { render, staticRenderFns } from "./Chatbot_w_3.vue?vue&type=template&id=1c1273de&scoped=true&"
var script = {}
import style0 from "./Chatbot_w_3.vue?vue&type=style&index=0&id=1c1273de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1273de",
  null
  
)

export default component.exports